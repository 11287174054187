@import 'styles/mixins';
@import 'styles/texts';

.city-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    padding: 8px 12px;
    background-color: #ffffff;
    cursor: pointer;

    &.selected {
        background-color: var(--ping-Grey-grey-100);
    }

    .city-label {
        @include body2;

        color: var(--ping-Grey-grey-800);
    }
}
