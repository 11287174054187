@import 'styles/mixins';
@import 'styles/texts';

.MuiAutocomplete-listbox .MuiAutocomplete-option.dropdown-list__option {
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
    align-items: center;
    justify-content: flex-start;
    min-height: auto;

    &.multiple {
        grid-template-columns: max-content 1fr;
        padding: 6px 10px;
    }
}

.MuiButtonBase-root.searchable-dropdown__button {
    width: 100%;
    display: inline-grid;
    grid-template-columns: 1fr max-content;
    gap: 8px;

    border-radius: 4px;
    border: 1px solid var(--ping-Grey-grey-300);
    padding: 4px 12px;
    text-align: left;

    color: var(--ping-Grey-grey-800);

    &.placeholder {
        color: var(--ping-Grey-grey-400);
    }

    svg {
        width: 20px;
        height: 20px;

        transition: transform 0.3s ease-in-out;
    }

    span {
        @include body2;
    }

    &[aria-describedby] {
        svg:last-child {
            transform: rotate(180deg);
        }
    }

    &:hover,
    &:active {
        border-color: var(--ping-Grey-grey-800);
    }
}
