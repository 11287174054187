@import 'styles/mixins';
@import 'styles/texts';

.proxy-user-option {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;

    .user-label {
        @include caption;
    }

    svg {
        flex: 20px 0 0;
    }
}

.MuiButtonBase-root.searchable-dropdown__button.log-chart__users-dropdown {
    width: 200px;

    .log-chart__users-dropdown__label {
        color: var(--ping-Grey-grey-800);

        &:not(.is-empty) {
            width: 100%;
            display: inline-flex;
            align-items: center;
            justify-content: space-between;
            padding-right: 8px;
        }
    }
}

.log-chart__users-dropdown__search {
    .autocomplete-input-wrapper {
        padding: 4px;

        .MuiInputBase-root.autocomplete-input {
            padding: 4px 8px;

            svg {
                width: 14px;
                height: 14px;
            }

            input {
                padding: 1px 0;
            }
        }
    }
}
