.form-users-dropdown {
    .user-option,
    .searchable-dropdown__label-container:has(svg) {
        display: grid;
        grid-template-columns: 20px 1fr;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
    }
}
