@import 'styles/mixins';

$container-md-gap: 16px;
$container-gap: 24px;

.configurator-tab {
    display: grid;
    gap: $container-gap;
    grid-template: 1fr auto / 5fr minmax(200px, 7fr);

    @include w($md) {
        grid-template: auto / 1fr;
        gap: $container-md-gap;
    }

    .info-banner {
        grid-column: 2;

        @include w($md) {
            grid-column: auto;
        }
    }

    .terminal-proxies {
        // form - banner - gap
        max-height: calc(790px - 106px - 24px);
        overflow: hidden;

        @include w($lg) {
            max-height: calc(860px - 180px - 24px);
        }

        @include w($md) {
            min-height: 320px;
            max-height: 640px;
            max-width: calc(100dvw - 2 * $container-md-gap);
        }
    }

    &:has(#ai-optimization) {
        .terminal-proxies {
            // form - banner - gap
            max-height: calc(862px - 132px - 24px);
            overflow: hidden;

            @include w($lg) {
                max-height: calc(932px - 180px - 24px);
            }

            @include w($md) {
                min-height: 320px;
                max-height: 640px;
                max-width: calc(100dvw - 2 * $container-md-gap);
            }
        }
    }
}

.generate-proxies-form {
    grid-row: 1 / 3;

    .xl-column {
        flex-direction: row;

        @include w($xl) {
            flex-direction: column;
        }
    }
}

.terminal-proxies {
    border-radius: 8px;
    background: var(--ping-Grey-grey-800);
    display: flex;
    flex-direction: column;

    overflow: hidden;

    &__screen {
        color: #fff;
        padding: 24px;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 2;
        overflow-y: auto;

        p {
            text-overflow: ellipsis;
            overflow: hidden;
        }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: transparent;
        }

        &::-webkit-scrollbar {
            width: 10px;
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--ping-Grey-grey-400);
            border-radius: 50px;
        }
    }
    &__footer {
        padding: 16px;
        display: flex;
        justify-content: space-between;
        margin-top: auto;
    }
}
