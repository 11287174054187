@import 'styles/texts';

.reconfigure-popup {
    .popup-container {
        margin-top: 12px;
    }

    .popup-content {
        padding: 0px 16px;

        &-title {
            @include h5;

            margin: 0;
            text-align: left;
            max-width: none;
        }
    }

    &-data {
        &-title {
            @include text-label;
            letter-spacing: -0.2px;
        }

        &-list {
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin-top: 16px;
        }

        &-item,
        &-total {
            @include text-label;

            display: flex;
            justify-content: space-between;
            align-items: center;

            p {
                letter-spacing: -0.2px;
                color: var(--ping-Grey-grey-600);
            }

            span {
                color: var(--ping-Grey-grey-800);
            }
        }

        &-total {
            margin-top: 24px;
        }

        &-bullet-list {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 8px;
            margin-top: 16px;

            .bullet-item {
                @include body2;

                display: grid;
                grid-template-columns: 20px 1fr;
                gap: 8px;
                align-items: center;
                justify-content: flex-start;

                color: var(--ping-Grey-grey-600);
            }
        }
    }
}
