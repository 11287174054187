@import "styles/texts";

.order-summary-sidebar-container {
    position: sticky;
    top: 0;
}

.order-submit-container {
    margin-top: 16px;
    display: flex;
    flex-direction: column
}

.order-summary {
    @include body2;

    h5 {
        margin-bottom: 16px;
    }
    &-divider {
        width: 100%;
        height: 1px;
        background-color: var(--ping-Grey-grey-300);
        margin: 16px 0;
    }
    &-row {
        @include body2;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span:first-child {
            color: var(--ping-Grey-grey-600);
        }
        & + & {
            margin-top: 8px;
        }
    }
    &.error {
        background-color: var(--ping-Red-red-50);
    }
}
.sidebar-payments {
    display: flex;
    justify-content: center;
    margin-top: 16px;
    gap: 20px;
    filter: grayscale(100%);
}