.popup-badge {
    position: absolute;
    top: 0px;
    right: 0px;
    transform: scale(1) translate(50%, -50%);
    transform-origin: 100% 0%;

    display: inline-grid;
    place-items: center;

    &.success {
        color: var(--ping-Green-green-600);
        background-color: var(--ping-Green-green-50);
    }

    &.delete,
    &.money_wavy {
        color: var(--ping-Red-red-600);
        background-color: var(--ping-Red-red-50);
    }

    &.warn,
    &.warn_rect {
        color: var(--ping-Orange-orange-600);
        background-color: var(--ping-Orange-orange-50);
    }

    &:not(.rounded) {
        border-radius: 6px;
    }

    &.rounded {
        border-radius: 50%;
        transform: scale(1) translate(25%, -25%);
    }

    &.base {
        padding: 12px;
    }

    &.md {
        padding: 16px;
    }
}
