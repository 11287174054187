@import 'styles/texts';
@import 'styles/mixins';

.popup-content {
    padding: 0 40px;

    &-title.MuiDialogTitle-root {
        @include h4;

        max-width: calc(254px + 28px);
        margin: 0 auto;
        padding: 0;
        text-align: center;
    }

    .MuiDialogContent-root {
        margin-top: 8px;
        padding: 0;
    }

    &-description {
        @include body1;

        color: #677079;
        text-align: center;
    }

    @include w($sm) {
        padding: 16px;
    }
}
