@import 'styles/mixins';
@import 'styles/texts';

.product-option {
    display: grid;
    grid-template-columns: max-content 1fr repeat(2, max-content);
    align-items: center;
    gap: 8px;

    .product-label {
        @include caption;
    }
}

.MuiButtonBase-root.searchable-dropdown__button.edit-user__products-dropdown {
    .edit-user__products-dropdown-label {
        color: var(--ping-Grey-grey-800);

        &:not(.is-empty) {
            width: 100%;
            display: inline-flex;
            align-items: center;
            justify-content: space-between;
            padding-right: 8px;
        }
    }
}

.edit-user__products-dropdown__search {
    .autocomplete-input-wrapper {
        padding: 4px;

        .MuiInputBase-root.autocomplete-input {
            padding: 4px 8px;

            svg {
                width: 14px;
                height: 14px;
            }

            input {
                padding: 1px 0;
            }
        }
    }
}
