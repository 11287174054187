@import 'styles/mixins';
@import 'styles/texts';

.country-option {
    display: grid;
    grid-template-columns: 20px 1fr;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    padding: 8px 12px;
    background-color: #ffffff;
    cursor: pointer;

    &.selected {
        background-color: var(--ping-Grey-grey-100);
    }

    .country-label {
        @include body2;

        color: var(--ping-Grey-grey-800);
    }
}
