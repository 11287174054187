@import 'styles/mixins';
@import 'styles/texts';

$gap: 16px;
$key-icon-size: 64px;

.info-banner {
    border-radius: 8px;
    border: 1px solid var(--ping-Blue-blue-100);
    background: var(--ping-Blue-blue-50);
    padding: 16px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: $gap;

    svg {
        flex-shrink: 0;
        margin-bottom: auto;
    }

    &__description {
        h5 {
            @include h6;

            line-height: 1.5;
        }

        .body2 {
            margin-top: 4px;
        }
    }

    .MuiChip-root {
        margin-left: auto;
        margin-top: auto;
    }

    &:is(.default) {
        @include w($lg) {
            flex-wrap: wrap;
        }

        .info-banner__description {
            @include w($lg) {
                flex: calc(100% - $key-icon-size - $gap);
            }
        }
    }

    &:is(.small) {
        @include w($xl) {
            flex-wrap: wrap;
        }

        .info-banner__description {
            @include w($xl) {
                flex: calc(100% - $key-icon-size - $gap);
            }
        }
    }
}
