@import 'styles/mixins';
@import 'styles/texts';

.MuiAutocomplete-listbox {
    .MuiAutocomplete-option.asn-option {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        padding: 8px 12px;
        background-color: #ffffff;
        cursor: pointer;

        &.selected {
            background-color: var(--ping-Grey-grey-100);
        }

        .asn-label {
            @include caption;

            color: var(--ping-Grey-grey-800);

            &-id {
                @include caption;

                color: var(--ping-Brand-brand-600);
            }
        }
    }
}
