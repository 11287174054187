.pin-wrapper {
    position: relative;
    display: block;
    width: 180px;
    height: 180px;
    margin: 0 auto;

    background-image: url(../../../images/popup/ellipse-bg.svg);
    background-position: center;
    background-repeat: no-repeat;

    &-pin {
        position: absolute;
        display: block;
        padding: 14px;
        width: 56px;
        height: 56px;

        box-shadow: 0px 10.5px 21px -7px #3b2cb133;
        background-color: #ffffff;
        border: 1px solid #e5e7f1;
        border-radius: 7px;
        color: var(--ping-Red-red-500);
    }
}
