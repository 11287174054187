@import 'styles/texts';

.auth-form {
    max-width: 400px;
    .custom-text-input {
        margin-top: 16px;
    }
    .custom-otp {
        margin-top: 24px;
    }
    &__title {
        color: var(--ping-Grey-grey-800);
        @include h2;
        & + .custom-text-input {
            margin-top: 24px;
        }
    }
    &__desc {
        color: var(--ping-Grey-grey-700);
        padding-top: 8px;
        @include body1;
    }

    .auth-button {
        margin-top: 24px;
    }

    &__text {
        @include body2;
        text-align: center;
        margin-top: 24px;
        color: var(--ping-Grey-grey-700);
        & + & {
            margin-top: 16px;
        }
    }

    &__support {
        @include body2;
        text-align: center;
        margin-top: 24px;
        color: var(--ping-Grey-grey-700);
        & + & {
            margin-top: 16px;
        }
        display: flex;
        justify-content: center;
    }

    &__error {
        @include body2;
        margin-top: 24px;
        color: var(--ping-Red-red-500);
        border: 1px solid var(--ping-Red-red-500);
        min-height: 30px;
        padding: 5px 0;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
    }
}

.auth-link {
    @include body2-link;
    color: var(--ping-Brand-brand-500);
    cursor: pointer;
}
.auth-divider {
    text-align: center;
    position: relative;
    @include body2;
    color: var(--ping-Grey-grey-700);
    background: transparent;
    margin: 16px 0;
    &::after,
    &:before {
        content: '';
        position: absolute;
        top: 50%;
        width: 46%;
        height: 1px;
        background-color: var(--ping-Grey-grey-300);
    }
    &::after {
        right: 0;
    }
    &:before {
        left: 0;
    }
}
