@import 'styles/texts';
@import 'styles/mixins';

.popup {
    .MuiPaper-root.MuiDialog-paper {
        border-radius: 8px;
        margin: 16px;
    }
    .MuiButtonBase-root.MuiIconButton-root.popup-close-button {
        position: absolute;
        top: 10px;
        right: 8px;
        color: var(--ping-Grey-grey-800);
    }

    .MuiPaper-root.MuiDialog-paper.MuiDialog-paperWidthSm {
        max-width: 500px;
        width: 100%;
    }
}
