@import 'styles/texts';

.sidebar {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-right: 1px solid var(--ping-Grey-grey-200);
    &-title {
        @include overline-text;
        padding-left: 12px;
        margin-bottom: 8px;
        color: var(--ping-Grey-grey-700);
    }
    &-header {
        min-height: 56px;
        padding: 8px 16px;
        display: flex;
        gap: 0 24px;
        .header-logo {
            margin-right: auto;
        }
        @include w($lg) {
            border-bottom: 1px solid var(--ping-Grey-grey-200);
        }
    }
    &-body {
        flex-grow: 1;
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 20px 0;
        .with-border {
            border-top: 1px solid var(--ping-Grey-grey-200);
            padding-top: 20px;
        }
    }
    &-footer {
        border-top: 1px solid var(--ping-Grey-grey-200);
    }
    .burger-button {
        width: 40px;
        height: 40px;
        padding: 0;
    }
}

.sidebar-nav-link {
    & + .sidebar-nav-link {
        margin-top: 4px;
    }
    &.MuiButtonBase-root {
        color: var(--main);
        border-radius: 4px;
        min-height: auto;
        height: 28px;
        padding: 0;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4;
        text-transform: capitalize;
        justify-content: flex-start;
        padding-left: 12px;
        gap: 0 8px;
        font-family: inherit;
        letter-spacing: normal;
        .MuiButton-startIcon {
            margin: 0;
        }
    }

    &.active:not(.fake) {
        color: var(--ping-Brand-brand-500);
        background-color: var(--ping-Brand-brand-50);
    }
}

.user-actions {
    display: flex;
    & > .MuiButtonBase-root.MuiIconButton-root {
        border-radius: 0;
    }
    &-item {
        &.MuiButtonBase-root.MuiButton-root {
            padding: 0 12px;
            gap: 0 12px;
            height: 36px;
            @include body2;
            .MuiButton-startIcon {
                margin: 0;
            }
        }
        &.active {
            color: var(--ping-Brand-brand-500);
            background-color: var(--ping-Brand-brand-50);
        }
    }
}

.user-info {
    padding: 12px 14px;
    min-height: 64px;
    display: flex;
    justify-content: space-between;

    &__data {
        max-width: 85%;

        &__email {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.nested-nav-link-container {
    .dropdown-links-item {
        padding-left: 26px;
    }
}

.dropdown-links {
    & + & {
        margin-top: 4px;
    }

    .nested-nav-link {
        margin-bottom: 0 !important;
        &:after {
            display: none;
        }
    }
    .sidebar-nav-link {
        &:after {
            transition: 0.3s;
            position: absolute;
            top: 50%;
            right: 8px;
            content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath id='Vector' d='M5 7.5L10 12.5L15 7.5' stroke='%234D5761' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            transform: translateY(-50%) rotate(-90deg);
            height: 20px;
            width: 20px;
        }
        &.opened {
            &:after {
                transform: translateY(-50%) rotate(0deg);
            }
        }
    }
    .sidebar-nav-link.opened + &-items {
        max-height: calc(28px * 5);
    }
    .sidebar-nav-link.open + &-items {
        max-height: calc(28px * 5);
    }
    &-items {
        max-height: 0;
        transition: 0.5s;
        overflow: hidden;
    }
    &-item {
        color: var(--main);
        @include body2;
        height: 28px;
        border-left: 2px solid #eae8fa;
        margin-left: 20px;
        padding-left: 20px;
        display: flex;
        align-items: center;
        transition: 0.3s;
        cursor: pointer;
        svg {
            margin-left: auto;
            margin-right: 10px;
        }
        &.active {
            border-left: 2px solid #5547eb;
            color: #5547eb;
        }
    }
}
.user-actions {
    border-radius: 50%;
    overflow: hidden;
}
.user-actions-item {
    &.MuiButtonBase-root.MuiButton-root {
        justify-content: flex-start;
    }
}

.user-balance {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 14px 0 14px;
}
