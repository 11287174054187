.bill-preview {
    position: relative;
    display: block;
    margin: 0 auto;

    .popup-badge {
        right: 50%;
        top: 20px;
        transform: translate(50%, 0%);
    }
}
