.cta {
    background-image: linear-gradient(225deg, #a192ec 0%, #5547eb 100%);
    background-position: center, center;
    background-size: contain, contain;
    display: flex;
    align-items: center;
    color: #ffffff;
    justify-content: center;
    height: fit-content;
    width: 100%;
    padding: 4px;
    padding-right: 14px;
    position: relative;
    transition: all 0.3s ease;

    &-title {
        text-align: center;
        font-size: 11px;
        color: #ffffff;
    }

    &-btn {
        position: absolute;
        height: 100%;
        right: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 12px;
        z-index: 9999;
    }

    &.hidden {
        max-height: 0;
        opacity: 0;
        margin: 0;
        padding: 0;
        overflow: hidden;
    }

    & > a {
        width: 100%;
        display: inline-block;
        text-align: center;
    }
}
