@import 'styles/mixins';
@import 'styles/texts';

.MuiButtonBase-root.country-select-button {
    width: 100%;
    display: inline-grid;
    grid-template-columns: 20px 1fr 20px;
    gap: 8px;

    border-radius: 4px;
    border: 1px solid var(--ping-Grey-grey-300);
    padding: 4px 12px;
    text-align: left;

    color: var(--ping-Grey-grey-800);

    &.placeholder {
        color: var(--ping-Grey-grey-400);
    }

    svg {
        width: 20px;
        height: 20px;
    }

    span {
        @include body2;
    }
}
