@import "styles/texts.scss";

.product-cards {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
}

.product-card {
    padding: 16px;
    flex: 1 0 255px;
    max-width: calc(50% - 8px);
    border-radius: 8px;
    border: 1px solid var(--ping-Grey-grey-200);
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 16px;
    @include w($sm) {
        max-width: none;
        flex: 1 1 100%;
    }
    svg {
        margin-bottom: -4px;
    }

    &__title {
        @include h6;
    }
    &__desc {
        @include caption;
    }
    &__price {
        margin-top: auto;
        padding: 8px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        span {
            &:nth-child(1) {
                @include overline-text;
                margin-right: auto;
            }
            &:nth-child(2) {
                @include h4;
                margin-right: 0.2em;
            }
            &:nth-child(3) {
                @include body1;
                color: var(--ping-Grey-grey-500);
            }
        }
    }
    &__list {
        @include caption;
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: auto;
        div {
            display: flex;
            align-items: flex-start;
            gap: 8px;
            svg {
                flex-shrink: 0;
            }
        }
    }
    &--datacenter {
        .product-card__price {
            background-color: var(--ping-Blue-blue-50);
        }
        .product-card__list {
            svg {
                color: var(--ping-Blue-blue-300);
            }
        }
    }
    &--isp {
        .product-card__price {
            background-color: var(--ping-Brand-brand-50);
        }
        .product-card__list {
            svg {
                color: var(--ping-Brand-brand-300);
            }
        }
    }
    &--residential {
        .product-card__price {
            background-color: var(--ping-Purple-purple-50);
        }
        .product-card__list {
            svg {
                color: var(--ping-Purple-purple-300);
            }
        }
    }
    .custom-button {
        margin-top: auto;
    }
}
