@import 'styles/texts';

.successful-sidebar {
    .order-summary-row {
        align-items: flex-start;
        span {
            &:first-child {
                white-space: nowrap;
                padding-right: 16px;
            }
            &:last-child {
                word-break: break-all;
                max-width: 65%;
            }
        }
    }

    h5 {
        margin-bottom: 8px;
    }
}

.label-box {
    display: inline-block;
    width: 100%;
    margin-bottom: 8px;
    font-size: 8px;
    line-height: 1;
    word-break: break-all;
    letter-spacing: 0;
}

.MuiTypography-root.price-id-label {
    @include body2;

    color: var(--ping-Grey-grey-800);

    &.mb-0 {
        margin-bottom: 0;
    }
}

.MuiTypography-root.checkout-id-label {
    @include caption;

    color: var(--ping-Grey-grey-600);
}
