.custom-table {
    .MuiDataGrid-cell[data-field='actions'] {
        justify-content: flex-end;
    }
    .table-no-data {
        height: 320px;
    }
}

.last-sticky-column {
    position: sticky !important;
    right: 0;
    background-color: #ffffff;
}
