.mastercard-preview {
    position: relative;
    margin: 0 auto;
    width: 198px;
    height: 120px;

    background-image: url(../../../images/popup/mastercard-preview.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
