$spacing: 8px;

.code-snip-container {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: $spacing;

    margin: 0 auto;
    padding: $spacing;
    border-radius: 6px;
    border: 1px solid #e5e7f1;
    background-color: #ffffff;

    box-shadow: 0 10px 15px -10px #5547eb33;

    code {
        background-color: var(--ping-Brand-brand-50);
        color: var(--ping-Brand-brand-800);
        padding: $spacing;
        border-radius: 2px;

        font-size: 6.4px;
        line-height: 1.3;
        letter-spacing: 0.6px;
        font-weight: 500;
        font-family: 'Source Code Pro', sans-serif;
    }
}

.dots-container {
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: $spacing / 2;

    .dot {
        display: inline-block;
        width: 6px;
        height: 6px;
        background-color: var(--ping-Grey-grey-300);
    }
}

.divider {
    width: calc(100% + 2 * $spacing);
    height: 1px;
    background-color: #e5e7f1;
}
