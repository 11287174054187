@import 'styles/texts.scss';

.label-component {
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    min-height: 20px;
    display: inline-flex;
    padding: 2px 6px;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    user-select: none;
    @include caption;

    &--outline {
        color: #ffffff;
        border: 1px solid #ffffff33;
        background: #ffffff1a;
    }
    &--grey {
        border-color: var(--ping-Grey-grey-300);
        background-color: var(--ping-Grey-grey-50);
        color: var(--ping-Grey-grey-600);
    }
    &--green {
        border-color: var(--ping-Green-green-300);
        background-color: var(--ping-Green-green-50);
        color: var(--ping-Green-green-800);
    }
    &--red {
        border-color: var(--ping-Red-red-300);
        background-color: var(--ping-Red-red-50);
        color: var(--ping-Red-red-800);
    }
    &--yellow {
        border-color: var(--ping-Orange-orange-300);
        background-color: var(--ping-Orange-orange-50);
        color: var(--ping-Orange-orange-800);
    }
    &--purple {
        border-color: var(--ping-Brand-brand-300);
        background-color: var(--ping-Brand-brand-50);
        color: var(--ping-Brand-brand-800);
    }
}
