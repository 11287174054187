.button-wrapper {
    position: relative;
}

.custom-button {
    height: 100%;
    text-align: center;

    &.loading {
        filter: blur(2px);
    }

    &__loading {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;

        &-spinner {
            display: flex;
            width: 1.5rem;
            height: 1.5rem;
            border: 3px solid rgba(0, 0, 0, 0.2);
            border-top: 3px solid var(--ping-Grey-grey-700);
            border-radius: 50%;
            animation: spin 1s linear infinite;
        }
    }
    &.MuiButton-contained {
        box-shadow: none;
    }
    &.color-default {
        &.MuiButton-contained {
            color: #fff;
            background-color: var(--ping-Brand-brand-500);

            &:hover {
                background-color: var(--ping-Brand-brand-700);
            }
            &.Mui-disabled {
                background-color: var(--ping-Grey-grey-200);
                color: var(--ping-Grey-grey-600);
            }
        }

        &.MuiButton-text {
            color: var(--ping-Brand-brand-500);
            &.Mui-disabled {
                color: var(--ping-Grey-grey-600);
            }
        }
        &.MuiButton-outlined {
            border-color: var(--ping-Grey-grey-300);
            &.Mui-disabled {
                color: var(--ping-Grey-grey-600);
                background-color: var(--ping-Grey-grey-200);
            }
            &.bg-white:not(:disabled) {
                background-color: #fff;
            }
        }
    }
    &.color-error {
        &.MuiButton-contained {
            color: #fff;
            background-color: var(--ping-Red-red-500);
            &:hover {
                background-color: var(--ping-Red-red-700);
            }
            &.Mui-disabled {
                background-color: var(--ping-Grey-grey-200);
                color: var(--ping-Grey-grey-600);
            }
        }
        &.MuiButton-outlined {
            border-color: var(--ping-Red-red-300);
            color: var(--ping-Red-red-600);
            &.bg-white {
                background-color: #fff;
            }
        }
    }
    &.color-orange {
        &.MuiButton-contained {
            color: #fff;
            background-color: var(--ping-Orange-orange-500);
            &:hover {
                background-color: var(--ping-Orange-orange-700);
            }
            &.Mui-disabled {
                background-color: var(--ping-Grey-grey-200);
                color: var(--ping-Grey-grey-600);
            }
        }
        &.MuiButton-outlined {
            border-color: var(--ping-Orange-orange-300);
            color: var(--ping-Orange-orange-600);
            &.bg-white {
                background-color: #fff;
            }
        }
    }
    &.button-without-text {
        padding: 0 10px;
        .MuiButton-icon {
            margin: 0;
        }
    }
}

.spinner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: inherit;
    z-index: 1;
}

@keyframes spin {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}
