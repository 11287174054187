@import 'styles/texts.scss';

.text-cell {
    color: var(--ping-Grey-grey-700);
    @include body2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    gap: 4px;
    &.capitalize {
        text-transform: capitalize;
    }
    & > div {
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.service-cell {
    display: flex;
    align-items: center;
    gap: 8px;
    &__icon {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        svg {
            font-size: 36px;
            width: 1em;
            height: 1em;
        }
    }

    &-title {
        @include subtitle2;
        color: var(--ping-Grey-grey-800);
    }
    &-info {
        display: flex;
        gap: 6px;
        align-items: center;
        color: var(--ping-Grey-grey-500);
        @include caption;
    }
}

.payment-cell {
    display: flex;
    align-items: center;
    &__icon {
        display: flex;
        align-items: center;
        margin-right: 8px;
    }
    &__value {
        display: flex;
        align-items: center;
        @include body2;
        gap: 4px;
    }
}

.card-dots {
    display: inline-flex;
    width: 32px;
    background-image: url('../../../icons/colored/card-dots.svg');
    height: 20px;
    background-repeat: no-repeat;
}

.log-chart-cell {
    width: 100%;
}
.usage {
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--ping-Grey-grey-700);
    margin-bottom: 4px;
    @include body2;

    &__divider {
        color: var(--ping-Grey-grey-400);
    }
}

.usage-no-limit {
    @extend .usage;
    margin-bottom: 0;
}

.password-cell {
    display: flex;
    align-items: center;
    @include body2;
    color: var(--ping-Grey-grey-700);
    width: 100%;
    position: relative;
    &__value {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: calc(100% - 30px);
    }
    &__button {
        flex-shrink: 0;
        display: flex;
        margin-left: 8px;
    }
}

.list-cell {
    display: flex;
    align-items: center;

    &__main {
        @include body2;
        color: var(--ping-Grey-grey-700);
    }
}

.amount-cell {
    text-align: right;
    width: 100%;
    @include body2;

    &[data-positive='true'] {
        color: var(--ping-Green-green-800);
    }
    &[data-positive='false'] {
        color: var(--ping-Red-red-600);
    }
}

.copy-cell {
    width: 100%;
    display: flex;
}

.copy-cell.end {
    justify-content: flex-end;
}

.copy-cell.start {
    justify-content: flex-start;
}

.copy-cell.center {
    justify-content: center;
}

.text-cell.copy-cell {
    & > span {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &:has(.MuiChip-root) {
        display: grid;
        grid-template-columns: 1fr max-content;
    }

    &:has(.copy-cell-hover-content) {
        .copy-cell-hover-content {
            display: none;
        }

        &:hover,
        &:active {
            .copy-cell-content {
                display: none;
            }

            .copy-cell-hover-content {
                display: inline;
            }
        }
    }
}

.usage__limit {
    & > span {
        font-size: 18px;
    }
}
