@import 'styles/texts';
@import 'styles/mixins';

.custom-dialog-fill-width {
    .MuiPaper-root.MuiDialog-paper.MuiDialog-paperWidthSm {
        max-width: 1200px !important;
    }
}

.custom-dialog {
    .MuiPaper-root.MuiDialog-paper {
        border-radius: 8px;
        margin: 16px;
    }
    .MuiTypography-root.MuiDialogTitle-root {
        @include h5;
        padding: 16px 24px 16px 16px;
    }
    .MuiButtonBase-root.MuiIconButton-root.dialog-close-button {
        position: absolute;
        top: 10px;
        right: 8px;
        color: var(--ping-Grey-grey-800);
    }
    .MuiDialogContent-root {
        padding: 8px 16px 24px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        color: var(--ping-Grey-grey-800);
        font-weight: 500;
        .body2 {
            color: var(--ping-Grey-grey-600);
        }
    }
    .MuiPaper-root.MuiDialog-paper.MuiDialog-paperWidthSm {
        max-width: 500px;
        width: 100%;
    }
    .MuiDialogActions-root {
        border-top: 1px solid var(--ping-Grey-grey-200);
        gap: 16px;
        padding: 16px;
        button {
            margin: 0;
        }
        @include w($sm) {
            button {
                width: 50%;
            }
        }
    }
}
