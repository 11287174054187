@import 'styles/mixins';
@import 'styles/texts';

$border-color: var(--ping-Grey-grey-200);
$container-border: 1px solid $border-color;
$border-radius: 4px;

.dropdown-root {
    z-index: 100000;
    width: 100%;
    background-color: #ffffff;
    border-radius: 6px;
    margin-top: 4px !important;
}

.autocomplete-input-wrapper {
    padding: 12px;
    padding-bottom: 8px;
    width: 100%;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    border-top: $container-border;
    border-inline: $container-border;
    background-color: #ffffff;

    .MuiInputBase-root.autocomplete-input {
        width: 100%;
        display: inline-grid;
        grid-template-columns: max-content 1fr;
        gap: 8px;

        border-radius: $border-radius;
        padding: 6px 8px;
        transition: border-color 0.3s ease;
        border: $container-border;

        svg {
            color: var(--ping-Grey-grey-500);
        }

        &.Mui-focused {
            border-color: var(--ping-Brand-brand-400);
        }

        & input {
            @include body2;
        }
    }
}

.autocomplete-popper {
    position: relative;
    width: 100%;
    transform: none;

    .MuiAutocomplete-paper {
        box-shadow: none;
        margin: 0;
        border: none;

        border-top: none;
        border-bottom: $container-border;
        border-inline: $container-border;

        border-radius: 0;
        border-bottom-right-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
    }

    &.MuiAutocomplete-popperDisablePortal {
        position: relative;
    }

    .MuiAutocomplete-listbox {
        padding: 0;
        background-color: #fff;
        min-height: auto;
        align-items: flex-start;

        .MuiAutocomplete-option {
            &.Mui-focused {
                background-color: var(--ping-Grey-grey-100);
            }
        }
    }
}
