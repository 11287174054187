.custom-tabs {
    .MuiTabPanel-root {
        padding: 24px 0 0;
    }
    &.without-padding {
        .MuiTabPanel-root {
            padding: 0;
        }
    }

    .MuiTabs-flexContainer {
        border-bottom: 1px solid var(--ping-Grey-grey-200);
        gap: 0 24px;
    }
    .MuiTabs-indicator {
        background-color: var(--ping-Brand-brand-500);
    }
    .MuiTab-root.Mui-selected {
        color: var(--main);
    }

    &:has(.child) {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .child {
        margin-left: auto;
    }
}
