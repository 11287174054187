@import 'styles/mixins';
@import 'styles/texts';

.generate-proxies-form {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: flex-start;
}

.proxies-form-block {
    @include box;

    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 16px;
    padding: 16px;

    &-title.MuiTypography-root {
        @include subtitle1;
    }

    .MuiFormControl-root {
        gap: 8px;
        max-width: calc(100dvw - 64px);

        .MuiFormLabel-root {
            margin-bottom: 0;
        }
    }

    .proxies-form-block-toggle-group {
        display: flex;
        flex-direction: row;
        gap: 8px;
        justify-content: flex-start;
        align-items: center;

        .toggle-group-option {
            @include subtitle2;
            color: var(--ping-Grey-grey-800);

            padding: 2px 8px;
            border-width: 1px;
            border-style: solid;
            border-radius: 4px;
            text-transform: none;

            border-color: var(--ping-Grey-grey-200);

            transition: color 0.3s ease, border-color 0.3s ease;

            svg {
                color: #677079;
                margin-right: 4px;
            }

            &.Mui-selected {
                color: var(--ping-Brand-brand-500);
                border-color: var(--ping-Brand-brand-500);

                svg {
                    color: var(--ping-Brand-brand-500);
                }

                &.ai-optimization {
                    background-color: var(--ping-Brand-brand-50);
                }
            }
        }
    }
}
