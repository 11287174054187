@import 'styles/mixins';
@import 'styles/texts';

.log-chart {
    margin-bottom: 24px;
    &__header {
        padding-bottom: 8px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 20px;
    }
    &__info {
        padding: 16px 0;
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
    }
    &-filters {
        display: flex;
        gap: 12px;
        flex-wrap: wrap;
    }
}
.metric-item {
    min-width: 160px;
    &__title {
        @include overline-text;
        color: var(--ping-Grey-grey-500);
        margin-bottom: 8px;
    }
    &__value {
        @include display-large;
        color: var(--ping-Grey-grey-800);
    }
    @include w($md) {
        min-width: auto;
        width: calc(50% - 16px / 2);
    }
}
