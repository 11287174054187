@import 'styles/texts';
@import 'styles/mixins';

.MuiDialogActions-root.dialog-actions {
    border-top: 1px solid var(--ping-Grey-grey-200);
    gap: 16px;
    padding: 16px;

    .MuiButton-outlined {
        border-color: var(--ping-Grey-grey-300);
    }

    .MuiButton-root {
        margin: 0;
        box-shadow: none;
    }

    &.single {
        justify-content: center;
    }
}
